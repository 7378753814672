<template>
  <div>
    <PageHeader :title="pageTitle" :items="breadcrumb" />
    <div style="width: 100%; margin: 10px 0">
      <b-card>
        <h4>Editar Feriado</h4>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="data">Data</label>
              <b-form-datepicker
                v-model="feriado.data"
                class="form-control"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                }"
                locale="pt-br"
              />
            </div>

            <div class="form-group">
              <label for="descricao">Descrição</label>
              <input
                type="text"
                class="form-control"
                v-model="feriado.descricao"
              />
            </div>

            <div class="form-group">
              <label for="tipo_feriado">Tipo</label>
              <b-form-select
                v-model="feriado.tipo_feriado"
                :options="tipos"
              ></b-form-select>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="municipio">Município</label>
              <input
                type="text"
                class="form-control"
                v-model="feriado.municipio"
              />
            </div>

            <div class="form-group">
              <label for="uf">UF</label>
              <b-form-select
                v-model="feriado.uf"
                :options="estadosOptions"
              ></b-form-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <button class="btn btn-primary" @click="updateFeriado">
              Atualizar
            </button>
          </div>
          <div class="col-md-6">
            <button class="btn btn-danger" @click="removeFeriado">
              Remover
            </button>
          </div>
        </div>
      </b-card>
      <!-- error message handling here -->
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/page-header';
import { update, remove } from './feriado.service';
import estados from '@/utils/estados';

export default {
  data() {
    return {
      pageTitle: 'Editar Feriado',
      breadcrumb: [
        {
          text: 'Dias Úteis',
          href: '/'
        },
        {
          text: 'Editar',
          href: '/'
        },
        {
          text: this.pageTitle,
          active: true
        }
      ],
      tipos: [
        { text: 'Nacional', value: 'N' },
        { text: 'Estadual', value: 'E' },
        { text: 'Municipal', value: 'M' }
      ],
      feriado: {},
      estadosOptions: estados()
    };
  },
  methods: {
    updateFeriado() {
      // Call the updateFeriado API service method to update the feriado
      update(this.feriado).then(() => {
        this.$router.push('/list-feriados');
      });
    },
    removeFeriado() {
      // Call the removeFeriado API service method to remove the feriado
      remove(this.feriado.id).then(() => {
        this.$router.push('/list-feriados');
      });
    }
  },
  created() {
    const data = this.$route.query.data;
    if (data != null) {
      this.feriado = JSON.parse(data);
    }
  },
  components: {
    PageHeader
  }
};
</script>
